<template>
  <div class="preregister">
    <div class="columns is-centered">
      <div class="column is-half">
        <div class="form">
          <preregister-form />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PreregisterForm from '@/components/PreregisterForm'

export default {
  name: 'Preregister',
  components: { PreregisterForm },
}
</script>
<style scoped lang="scss">
@import '../assets/scss/_variables.scss';

.preregister {
  background-color: $aqua;
}
.form {
  padding: 2rem;
}
</style>
